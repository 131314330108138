<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item>
				<el-input v-model="keywords" placeholder="医生姓名/手机号" clearable></el-input>
			</el-form-item>
			<el-form-item label="审核状态：">
				<el-select v-model="applyStatus" placeholder="请选择" style="width: 120px;" clearable>
					<el-option
						v-for="item in applyStatuss"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="科室：">
				<el-select v-model="deptId" placeholder="请选择" clearable>
					<el-option
						v-for="item in depts"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
				<el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="name" header-align="center" width="100" align="center" label="名称"></el-table-column>
			<el-table-column prop="titleName" header-align="center" width="130" align="center" label="医生职称"></el-table-column>
			<el-table-column prop="mobilePhone" header-align="center" width="120" align="center" label="手机号"></el-table-column>
			<el-table-column prop="deptName" header-align="center" width="100" align="center" label="科室"></el-table-column>
			<el-table-column prop="hospitalName" header-align="center" align="center" label="医院名称"></el-table-column>
			<el-table-column prop="percentage" header-align="center" align="center" label="药品提成比例"></el-table-column>
			<el-table-column prop="outpatientCarePercentage" header-align="center" align="center" label="诊费提成比例"></el-table-column>
			<el-table-column prop="applyStatus" header-align="center" align="center" label="审核状态">
				<span slot-scope="scope">
					{{
						scope.row.applyStatus === 1 ? '待审核' :
						scope.row.applyStatus === 2 ? '审核通过' :
						scope.row.applyStatus === 3 ? '审核未通过': '未认证'
					}}
				</span>
			</el-table-column>
			<el-table-column header-align="center" align="center" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.applyId, 3)">查看</el-button>
					<el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.applyId, 2)">修改</el-button>
					<el-button v-if="scope.row.applyStatus !== 2" type="text" size="small" @click="deleteHandle(scope.row.applyId)">删除</el-button>
					<el-button v-if="scope.row.applyStatus === 1" type="text" size="small" @click="doApp(scope.row.applyId)">审核</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>
<script>
// import { dateFormat } from '@/utils';
export default {
	name: 'Doctor',
	data () {
		return {
			keywords: '',
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			applyStatus: '',
			applyStatuss: [
				{ label: '全部', value: '' },
				{ label: '待审核', value: 1 },
				{ label: '审核通过', value: 2 },
				{ label: '审核未通过', value: 3 }
			],
			deptId: '',
			depts: []
		};
	},
	created () {
		this.search();
		this.getDepts();
	},
	methods: {
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('doctorApplyList', {
				keywords: this.keywords,
				applyStatus: this.applyStatus,
				deptId: this.deptId,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows;
				}
			});
		},
		getDepts () {
			this.$root.request('deptList', {
				page: 1,
				limit: 1000
			}).then((data) => {
				if (data) {
					this.depts = data.rows.map(r => {
						return { label: r.name, value: r.id };
					});
				}
			});
		},
		addOrUpdateHandle (applyId, editType) {
			this.$root.go('/mnt/addOrUpdateDoctor', { applyId, editType });
		},
		deleteHandle (id) {
			this.$confirm('确认要删除该审核资料吗？', '提示', {
				distinguishCancelAndClose: true,
				confirmButtonText: '删除',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$root.request('doctorApplyDelete', { id }).then((data) => {
					if (data) {
						this.$root.elMsg('修改成功');
						this.getData();
					}
				});
			}).catch(() => {});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		doApp (id) {
			this.$confirm('审核医生申请', '提示', {
				distinguishCancelAndClose: true,
				confirmButtonText: '通过',
				cancelButtonText: '不通过',
				type: 'warning'
			}).then(() => {
				this.doApplication(id, 2);
			}).catch((action) => {
				if (action === 'cancel') {
					this.doApplication(id, 3);
				}
			});
		},
		doApplication (id, status) {
			this.$root.request('doctorDoApp', {
				id,
				status
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('修改成功');
					this.getData();
				}
			});
		}
	}
};
</script>